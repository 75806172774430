@font-face {
  font-family: 'LemonMilk';
  src: url('../fonts/LemonMilk.otf');
}

@font-face {
  font-family: 'LemonMilk';
  src: url('../fonts/LEMONMILK-Bold.otf');
  font-weight: bold;
}

@font-face {
  font-family: 'LemonMilk';
  src: url('../fonts/LEMONMILK-Medium.otf');
  font-weight: 500;
}
