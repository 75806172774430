@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('fonts.scss');

* {
  scroll-behavior: smooth;
}

html {
  height: 100%;
}

body {
  min-height: 100%;
  height: 100vh;
}
